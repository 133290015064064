var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('layout-wide',[_c('sub-navbar',{attrs:{"page":"Reminder"}}),_c('HeroBox',{attrs:{"title":"Send to Kronofogden","subtitle":"Please Select an invoice to mark as sent to Kronofogden","color":"is-danger"}}),_c('div'),_c('div',[(_vm.loading)?_c('div',[_vm._v("...Loading Please wait!!!")]):_vm._e(),(!_vm.loading)?_c('div',[_c('div',{staticClass:"table-color color: white"},[_c('div',[_vm._v(" Unpaid Customer's Invoices Kronofogden Process - Send to Kronofogden ")]),_c('div',[_c('b-button',{staticClass:"margin-top-20 width-300 text-bolder",staticStyle:{"background-color":"rgb(52, 136, 206)","color":"white"},on:{"click":_vm.refreshData}},[_vm._v(" Refresh the data ")]),(_vm.showWhich === 'notSent')?_c('b-button',{staticClass:"margin-top-20 margin-left-15 width-300",staticStyle:{"background-color":"rgb(61, 196, 134)","color":"white"},on:{"click":function($event){return _vm.getSentToKronofogden()}}},[_vm._v(" Get the invoices sent to Kronofogden ")]):_vm._e(),(_vm.showWhich === 'sent')?_c('b-button',{staticClass:"margin-top-20 margin-left-15 width-300",staticStyle:{"background-color":"rgb(182, 90, 92)","color":"white"},on:{"click":function($event){return _vm.getSentToInkassoNotSentToKronofogden()}}},[_vm._v(" Get invoices not sent to Kronofogden ")]):_vm._e()],1),_c('div',{staticStyle:{"float":"right","margin-top":"-30px"}},[_vm._v(" "+_vm._s(_vm.tag)+": "+_vm._s(_vm.roundedCurrencyAddedNumber(_vm.totalBalance * 100))+" ")])]),_c('div',[_c('vue-good-table',{key:_vm.keyTable,attrs:{"id":"selected_dispatches","columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table striped bordered","theme":"black-rhino","fixed-header":true,"search-options":{
							enabled: true,
						},"pagination-options":{
							enabled: true,
							mode: 'records',
							perPage: 100,
							position: 'bottom',
							perPageDropdown: [5, 10, 15, 20, 100],
							dropdownAllowAll: false,
							setCurrentPage: 1,
							jumpFirstOrLast: true,
							firstLabel: 'First',
							lastLabel: 'Last',
							nextLabel: 'next',
							prevLabel: 'prev',
							rowsPerPageLabel: 'Rows per page',
							ofLabel: 'of',
							pageLabel: 'page', // for 'pages' mode
							allLabel: 'All',
						}},on:{"on-row-dblclick":_vm.onRowDoubleClick,"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'kronofogden')?_c('span',[_c('b-button',{staticClass:"is-small is-info"},[_vm._v(_vm._s(_vm.doWhat))])],1):(props.column.field === 'reminderDueDate')?_c('span',[_c('span',{staticClass:"text-bold text-purple"},[_vm._v(" "+_vm._s(props.row.reminderDueDate)+" ")])]):(props.column.field === 'invoiceBalance')?_c('span',[_c('p',{staticClass:"text-bold text-red text-align-right"},[_vm._v(" "+_vm._s(props.row.invoiceBalance)+" ")])]):(props.column.field === 'invoiceNumber')?_c('span',[_c('p',{staticClass:"text-bold has-text-link text-align-right"},[_c('a',{staticStyle:{"text-decoration-line":"underline"},attrs:{"href":"url"}},[_vm._v(" "+_vm._s(props.row.invoiceNumber)+" ")])])]):(props.column.field === 'reminderId')?_c('span',[_c('p',{staticClass:"text-bold has-text-link text-align-right"},[_c('a',{staticStyle:{"text-decoration-line":"underline"},attrs:{"href":"url"}},[_vm._v(" "+_vm._s(props.row.reminderId)+" ")])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,3229156847)})],1),_c('div',{staticClass:"table-color",staticStyle:{"margin-top":"-30px","min-height":"120px","margin-bottom":"50px"}},[_c('download-csv',{attrs:{"data":_vm.rows,"name":"kronofogden-list.csv"}},[_c('div',{staticStyle:{"float":"right"}},[_c('b-button',{staticClass:"width-250 margin-bottom-20; margin-top-30",attrs:{"type":"is-danger","outlined":""}},[_vm._v(" Download the List ")])],1)])],1)]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }